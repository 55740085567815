var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-table',{attrs:{"size":"middle","data-source":_vm.list,"columns":_vm.columns,"pagination":false,"bordered":"","rowKey":(record, index) => index},scopedSlots:_vm._u([{key:"Created",fn:function(text){return _c('a-tooltip',{attrs:{"placement":"topLeft","title":text['Created']}},[_c('span',[_vm._v(_vm._s(_vm.parseTime(text["Created"])))])])}},{key:"ipam",fn:function(text){return _c('a-tooltip',{attrs:{"placement":"topLeft","title":`${text && text.driver}  ${
      text &&
      text.config
        .map((item) => {
          return ('网关：' + item.gateway || '') + '#' + ('子网掩码：' + item.subnet || '');
        })
        .join(',')
    }`}},[_c('span',[_vm._v(_vm._s(text && text.config .map((item) => { return (item.gateway || "") + "#" + (item.subnet || ""); }) .join(",")))])])}},{key:"tooltip",fn:function(text){return _c('a-tooltip',{attrs:{"placement":"topLeft","title":text}},[_c('span',[_vm._v(_vm._s(text))])])}},{key:"id",fn:function(text){return _c('a-tooltip',{attrs:{"title":text}},[_c('span',[_vm._v(" "+_vm._s(text.split(":")[1].slice(0, 12)))])])}},{key:"operation",fn:function(text, record){return [_c('a-space',[_c('a-tooltip',{attrs:{"title":"删除"}},[_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.doAction(record, 'remove')}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)]}}])},[_c('template',{slot:"title"},[_c('a-space',[_c('a-input',{staticClass:"search-input-item",attrs:{"placeholder":"名称"},on:{"pressEnter":_vm.loadData},model:{value:(_vm.listQuery['name']),callback:function ($$v) {_vm.$set(_vm.listQuery, 'name', $$v)},expression:"listQuery['name']"}}),_c('a-input',{staticClass:"search-input-item",attrs:{"placeholder":"id"},on:{"pressEnter":_vm.loadData},model:{value:(_vm.listQuery['networkId']),callback:function ($$v) {_vm.$set(_vm.listQuery, 'networkId', $$v)},expression:"listQuery['networkId']"}}),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.loadData}},[_vm._v("搜索")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }